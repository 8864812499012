import * as React from "react";
const SvgLogoSmall = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 261.4 261.4", style: {
  enableBackground: "new 0 0 261.4 261.4"
}, ...props }, /* @__PURE__ */ React.createElement("style", null, "\n	.color-fill {\n		fill: #FAB616;\n	}\n	@media (prefers-color-scheme: dark) {\n		.color-fill {\n		fill: #FAB616;\n		}\n	}\n\n	.clapper-fill {\n		fill: #FAB616;\n	}\n  "), /* @__PURE__ */ React.createElement("path", { className: "clapper-fill", d: "M98.2,200.3c0,17.9,14.5,32.3,32.3,32.3l0,0c17.9,0,32.3-14.5,32.3-32.3l0,0H98.2z" }), /* @__PURE__ */ React.createElement("path", { className: "clapper-fill", d: "M202.8,120.1c23.4,6.9,35.4,40.3,37.9,65l-100.4,0.3l0-159.7C191.5,26.3,224.9,70.2,202.8,120.1z" }), /* @__PURE__ */ React.createElement("circle", { style: {
  display: "none",
  fill: "none",
  stroke: "#FAB616",
  strokeMiterlimit: 10
}, cx: 130.7, cy: 130.7, r: 130.2 }), /* @__PURE__ */ React.createElement("rect", { x: 20.7, y: 161.4, className: "color-fill", width: 100.4, height: 24.1 }), /* @__PURE__ */ React.createElement("rect", { x: 58.6, y: 109.6, className: "color-fill", width: 62.6, height: 24.1 }), /* @__PURE__ */ React.createElement("rect", { x: 39.6, y: 57.1, className: "color-fill", width: 81.6, height: 24.1 }));
export default SvgLogoSmall;
